import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import { Archive } from '@common/core/models';
import { ArchivesService, LoaderService, StoreService } from '@common/core/services';
import { EBitfCloseEventStatus, EBitfUiMessageType, eStoreActions } from '@enums';
import { IBitfCloseEvent, IBitfGraphQlResponse, IBulkUpdateItemsDialogData } from '@common/interfaces';
import { ToastMessagesService } from '@web/core/services';
import { DynamicItemDataFormComponent } from '../dynamic-item-data-form/dynamic-item-data-form.component';

@Component({
  selector: 'mpa-bulk-update-items-dialog',
  templateUrl: './bulk-update-items-dialog.component.html',
  styleUrls: ['./bulk-update-items-dialog.component.scss'],
})
export class BulkUpdateItemsDialogComponent implements OnInit {
  @ViewChild(DynamicItemDataFormComponent) itemDataform: DynamicItemDataFormComponent;

  form: UntypedFormGroup;
  concatChanges = false;
  constructor(
    public dialogRef: MatDialogRef<BulkUpdateItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IBulkUpdateItemsDialogData,
    private archivesService: ArchivesService,
    private storeService: StoreService,
    private loaderService: LoaderService,
    private toastMessagesService: ToastMessagesService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.dialogData.bulkItemsIds?.length === 1) {
      this.loadData();
    }
  }

  onSave() {
    this.loaderService.show();
    const formArchive: Archive = new Archive(undefined, this.itemDataform.form.value);
    const bulkArchive: Partial<Archive> = Archive.getArchiveForBulkUpdate(formArchive);

    const observableList = this.dialogData.bulkItemsIds.map(id =>
      this.archivesService.getItemById({ id, disableHideLoader: true }).pipe(
        switchMap(response => {
          return this.archivesService.updateItem({
            id,
            body: new Archive(undefined, {
              ...response.content,
              ...(this.concatChanges ? Archive.concatArchive(response.content, bulkArchive) : bulkArchive),
            }),
            disableHideLoader: true,
          });
        })
      )
    );

    this.archivesService
      .getThrottledObservables(observableList, 3)
      .pipe(
        tap((response: IBitfGraphQlResponse<Archive>[]) => {
          response.forEach(res => {
            this.storeService.updateGalleryItem({ id: res.content.id, newItem: res.content });
          });
        }),
        tap(() => this.storeService.setStore(() => {}, eStoreActions.EVENT_UPDATE_ARCHIVES_TOTAL_VALUE)),
        tap(() => {
          this.toastMessagesService.show({
            title: this.translateService.instant(bitfToTranslate('ITEM.TOAST.ITEMS_UPDATED')),
            type: EBitfUiMessageType.SUCCESS,
          });
        }),
        catchError(error => {
          this.toastMessagesService.show({
            title: this.translateService.instant(bitfToTranslate('ITEM.TOAST.ERROR_UPDATING_ITEMS')),
            type: EBitfUiMessageType.ERROR,
          });
          return error;
        }),
        finalize(() => {
          this.loaderService.hide();
          this.dialogRef.close({
            status: EBitfCloseEventStatus.OK,
          } as IBitfCloseEvent<Archive>);
        })
      )
      .subscribe();
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<any>);
  }

  private loadData() {
    this.archivesService
      .getItemById({ variables: { id: this.dialogData.bulkItemsIds[0] } })
      .subscribe((response: IBitfGraphQlResponse<Archive>) => {
        this.storeService.setSelectedItem(response.content);
      });
  }
}
